$color-brdgreen: #1d4a30;

html,
html {
    font-family: 'Roboto', sans-serif;
}

body {
    font-size: 13px;
    line-height: 20px;
    overflow: hidden;
}

span.link {
    color: #559902;
    cursor: pointer;
}

header {
    .menuHeader {
        padding: 1.3em 0 1.3em 1em;
        .firstline {
            font-size: 2em;
            display: flex;
            align-items: center;
            img {
                margin-right: 10px;
            }
        }
    }
    .menu-button {
        float: right;
    }
}
.maintenance {
    width: 100%;
    .maintenanceTitle {
        font-size: 3em;
        line-height: 2em;
        margin-top: 2em;
        text-align: center;
    }
    .maintenanceImage {
        width: 341px;
        height: 336px;
        background: url("../images/maintenance.jpg") no-repeat;
        margin: auto;
    }
    .maintenanceInfo {
        font-size: 2em;
        line-height: 2em;
        margin-top: 2em;
        text-align: center;
    }
}

.hup {
    margin-top: 1em;
    padding: 1em;
    .hupc {
        display: flex;
        justify-content: flex-start;
        .hupc-i {
            margin-left: 1em
        }
    }
    .hupa {
        display: flex;
        justify-content: flex-end;
    }
}

.headroom {
    z-index: 1000 !important;
    .headerTitle img {
        top: 12px;
        position: relative;
        margin-right: 0.5em;
    }
    .headerUserComp {
        display: flex;
        align-items: center;
        .headerUserName {
            font-size: 1.3em;
            color: white;
            padding-right: 0.5em;
        }
    }
}

.login {
    background: url("../images/login-screen.jpg") no-repeat;
    background-size: cover;
    height: 1267px;
    color: #FAFAFA;
    .login__box {
        max-width: 450px;
        margin: auto;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        max-height: 450px;
        .logo {
            background: url(../images/logo.png) no-repeat;
            width: 150px;
            height: 150px;
            margin-left: auto;
            margin-right: auto;
            left: 0;
            position: absolute;
            right: 0;
            top: -75px;
        }
        form {
            padding-top: 20px;
            margin: auto;
            h1 {
                padding: 20px;
                color: #a2c99f;
                margin: 1.3em 0 0 0;
                text-align: center;
            }
            .formField {
                input {
                    color: lightgray !important;
                }
                label {
                    color: lightgray !important;
                }
                //width: calc(100% - 40px) !important;
            }
        }
    }
}

div.centered {
    text-align: center;
}

div.spacer {
    width: 100% !important;
    height: 1.5em;
    display: block;
}

.form {
    padding-top: 20px;
    margin: auto;
    h1 {
        padding: 20px;
        color: #00467e;
    }
    .formField {
        padding: 0 20px 15px 20px;
        width: calc(100% - 40px) !important;
    }
    .formButtons {
        padding: 10px;
        float: right;
    }
    .otherLogins {
        padding: 20px;
        margin-top: 50px;
    }
}

@media (max-width: 400px) {
    .form {
        width: 100%;
    }
}

@media (min-width: 400px) {
    .form {
        width: 100%;
    }
}

#duties {

    .duty-status {
        padding-left: 2em;
        display: inline-block;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 60px;
        margin: auto;
        height: inherit;
        div {
            font-size: 20px;
            line-height: 40px;
            float: right;
            margin: 0.8em;
        }
    }
}

.status-text {
    .text-normal {
        color: #373737;
    }
    .text-ok {
        color: $color-brdgreen;
    }
    .text-warn {
        color: #ff7600;
    }
    .text-error {
        color: #ba0900;
    }
}

.badge {
    padding: 13px !important;
    left: -13px;
    top: -10px;
}

div.li-right-menu {
    display: block;
    position: absolute;
    top: 0;
    right: 4px;
}

#disabled div {
    margin-bottom: 1em;
}

.filter {
    padding: 0.6em;
    font-size: 1.3em;
    display: inline-block;
    div.filterEntry {
        float: left;
        .filterLabel {
            margin: 0 0.5em 0 1em;
        }
    }
    .filterLabel {
        font-weight: bold;
    }
}

.formRow {
    clear: both;
}

.formRow.divider {
    display: block;
    width: 100%;
    margin-top: 1em;
}

.formCol {
    float: left;
    padding: 0.5em;
}

.formLabel {
    font-size: 1.2em;
    position: relative;
    top: 14px;
}

planning {
    .planningEntry {
        .planningEntry-heading {
            display: flex;
            justify-content: space-between;
            .info.infoHeading {
                div {
                    float: left;
                    margin-right: 1em;
                }
                .info-line.name {
                    font-weight: bold;
                    color: $color-brdgreen;
                }
            }
            .info.body {
                clear: both;
            }
            .duty-status {
                display: flex;
                justify-content: flex-start;
                .status-text {
                    font-size: 20px;
                    line-height: 40px;
                    float: right;
                    margin: 0 0.8em 0.8em 0.8em;
                }
            }
        }
        .planningEntry-confirmed {
            display: flex;
            justify-content: flex-start;
            flex-wrap: wrap;
        }
    }
}

/* Users page */
#users {
    .usersTable {
        .row.inactive {
            color: #a7a7a7 !important;
        }
    }
}

.usersTable {
    span.userBadge {
        color: white;
        background-color: #425040;
        border-radius: 0.5em;
        padding: 0.1em 0.4em 0.1em 0.4em;
    }
}
.settingsForm {
    display: inline-table;

}

@media (min-width: 1200px) {
    .formRow {
        display: inline-flex;
        width: 50%;
        .formCol.label {
            min-width: 300px;
            font-size: 15px;
            text-align: right;
            margin-right: 20px;
        }
        .formCol.field {
            width: 100%;
        }
    }
}

@media (min-width: 600px) {
    .formCol.label {
        min-width: 220px;
        font-size: 13px;
        text-align: right;
        margin-right: 15px;
    }
    .formCol.field {
        width: 100%;
    }
}

@media (min-width: 551px) {
    .formRow {
        display: flex;
        .formCol.label {
            min-width: 260px;
            font-size: 13px;
            text-align: right;
            margin-right: 20px;
        }
        .formCol.field {
            width: 100%;
        }
    }
}

@media (max-width: 550px) {
    .formRow {
        .formCol.label {
            width: 100%;
            font-size: 10px;
            text-align: left;
            margin-right: 20px;
        }
        .formCol.field div {
            width: 100%;
        }
    }
}

div.paperTitle {
    margin: 10px 20px 10px 20px;
    font-size: x-large;
}

div.paperTitleSub {
    margin: 10px 20px 10px 20px;
    color: #373737;
    font-size: large;
}

.addOthersItem span {
    text-align: right;
    width: 100%;
}

.pageContent {
    padding: 0 2em 2em 2em;
}

/* Import */
.section {
    .sectionDescription {
        text-align: left;
        padding: 1em 0 1em 0;
        font-size: 1.1em;
    }
    .sectionButtons {
        display: flex;
        justify-content: flex-start;
    }
}

.importData {
    display: flex;
    justify-content: flex-start;
}

.importDescription {
    display: flex;
    justify-content: flex-start;
    padding: 1em 0 1em 0;
    font-size: 1.3em;
    color: dimgray;
}

.fileUpload {
    .dropzone {
        width: 50%;
        height: 20%;
        padding: 20px;
        margin: 5px;
        border: 8px dashed #9d9d9d;
        border-radius: 20px;
        text-align: center;
        cursor: pointer;
        div {
            display: inline-block;
            color: #9d9d9d;
            font-size: 2.5em;
            font-weight: bolder;
        }
    }
    .dropFiles {
        padding: 1em 1em 1em 0;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
    }
}

.planningChip-unConfirmed {
    opacity: 0.2;
}